import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router';

import { Box, Button, Grid, TextField, Typography } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';
import ImageUploadField from '~/components/Common/Forms/ImageUploadField';
import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';
import Spinner from '~/components/Common/Spinner';
import InputCruiseLineSelect from '~/components/Cruises/components/Selects/InputCruiseLineSelect';
import InputRateCodeSelect from '~/components/Cruises/components/Selects/InputRateCodeSelect';

import { formatDateDateTimeWidget, newDate } from '~/services/TimeService';

import cruiseDealStatus from '~/utils/cruiseDealStatus';
import { noop } from '~/utils/noop';

import DealInclusionList from '../DealPageEdit/DealInclusion/DealInclusionList';
import { INITIAL_FORM_STATE } from '../constants';
import { CruiseDealPage, CruiseDealPageCreation } from '../types';

type Props = {
  dealPage?: CruiseDealPage;
  isFetching?: boolean;
  onSubmit: (formValues: CruiseDealPageCreation) => void;
};

function DealPageForm({ dealPage, isFetching, onSubmit = noop }: Props) {
  const history = useHistory();
  const [formValues, setFormValues] = useState<CruiseDealPage>(INITIAL_FORM_STATE);
  const [selectedRateIds, setSelectedRateIds] = useState<Array<string>>([]);
  const [selectedRateCodes, setSelectedRateCodes] = useState<Array<string>>([]);
  const [countRefreshMarkdown, setCountRefreshMarkdown] = useState(0);
  const [previousCruiseLineId, setPreviousCruiseLineId] = useState<string>('');

  useEffect(() => {
    if (dealPage) {
      setFormValues(dealPage);
      setSelectedRateIds(dealPage.rates.map((rate) => rate.id));
      setSelectedRateCodes(dealPage.rates.map((rate) => rate.code));
      setPreviousCruiseLineId(dealPage.cruiseLine.id);
    }
  }, [dealPage]);

  // Used to force a refresh of the Markdown editors
  useEffect(() => {
    setCountRefreshMarkdown(countRefreshMarkdown + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dealStatus = useMemo(() => {
    const { startDate, endDate } = formValues;
    if (!startDate || !endDate) {
      return;
    }

    return cruiseDealStatus({ startDate, endDate });
  }, [formValues]);

  const getSlug = useCallback((value: string) => {
    if (!value) {
      return '';
    }

    return value
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .trim();
  }, []);

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      if (event?.target?.name) {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value ?? undefined,
        });
      }
    },
    [formValues],
  );

  const handleSlugNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      if (event?.target?.name) {
        setFormValues((prev) => ({ ...prev, slug: getSlug(event.target.value) }));
      }
    },
    [getSlug],
  );

  const handleChangeMarkdown = useCallback((value: string) => {
    setFormValues((prev) => ({ ...prev, termsAndConditions: value }));
  }, []);

  const clearRates = useCallback(() => {
    setSelectedRateIds([]);
    setSelectedRateCodes([]);
  }, []);

  const handleCruiseLineField = useCallback(
    (cruiseLine: API.CruiseLine) => {
      if (previousCruiseLineId && previousCruiseLineId !== cruiseLine.id) {
        clearRates();
      }

      setPreviousCruiseLineId(cruiseLine.id);
      setFormValues({
        ...formValues,
        cruiseLine: {
          id: cruiseLine.id,
          name: cruiseLine.name,
          code: cruiseLine.code,
          whiteImageId: null,
        },
      });
    },
    [formValues, previousCruiseLineId, clearRates],
  );

  const handleAddImage = useCallback((_: string, imageId: string) => {
    setFormValues((prev) => ({ ...prev, imageId }));
    // Return a promise because the ImageUploadField expects a promise
    return Promise.resolve();
  }, []);

  const handleCancel = useCallback(() => {
    history.push(`/cruises/deals`);
  }, [history]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit({
        name: formValues.name,
        heading: formValues.heading,
        subHeading: formValues.subHeading,
        termsAndConditions: formValues.termsAndConditions,
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        cruiseLineId: formValues.cruiseLine.id,
        slug: formValues.slug,
        imageId: formValues.imageId,
        rateIds: selectedRateIds,
      });
    },
    [
      formValues.cruiseLine.id,
      formValues.endDate,
      formValues.heading,
      formValues.imageId,
      formValues.name,
      formValues.slug,
      formValues.startDate,
      formValues.subHeading,
      formValues.termsAndConditions,
      onSubmit,
      selectedRateIds,
    ],
  );

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="name"
            label="Deal Page Name"
            inputProps={{ maxLength: 60 }}
            helperText="The maximum length is 60 characters."
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeField(event);
              handleSlugNameChange(event);
            }}
            value={formValues.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="slug"
            label="Deal Page Slug"
            onChange={handleChangeField}
            value={formValues.slug}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="heading"
            label="Headline"
            onChange={handleChangeField}
            inputProps={{ maxLength: 60 }}
            value={formValues.heading}
            helperText="The maximum length is 60 characters."
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="subHeading"
            label="Sub-headline"
            onChange={handleChangeField}
            inputProps={{ maxLength: 150 }}
            value={formValues.subHeading}
            helperText="The maximum length is 150 characters."
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Terms & Conditions</Typography>
          <MarkdownEditor
            // Used to force a refresh of the Markdown editors
            key={`termsAndConditions_${countRefreshMarkdown}`}
            value={formValues.termsAndConditions}
            onChange={handleChangeMarkdown}
          />
        </Grid>
        <Grid item xs={12}>
          <ImageUploadField
            col={12}
            label="Hero image"
            field_key="imageId"
            value={formValues.imageId}
            multiple={false}
            onUpload={handleAddImage}
            imageFullWidth
            imageFitType="contain"
          />
        </Grid>

        <Grid item xs={4} marginTop={2}>
          <InputCruiseLineSelect
            label="Cruise line"
            value={formValues.cruiseLine.id}
            onChange={handleCruiseLineField}
            required
          />
        </Grid>

        <Grid item container xs={12} spacing={2} marginTop={2}>
          <Grid item xs={2} alignSelf="center">
            <Typography>Schedule [AEST]</Typography>
          </Grid>
          <Grid item xs={3}>
            <DateTimeWidget
              defaultTimezone="Australia/Sydney"
              value={formValues.startDate}
              onChange={(date) =>
                setFormValues((prev) => ({
                  ...prev,
                  startDate: date.length ? formatDateDateTimeWidget(new Date(date)) : '',
                }))
              }
              format="DD/MM/YYYY HH:mm"
              label="Start Date"
            />
          </Grid>
          <Grid item xs={3}>
            <DateTimeWidget
              defaultTimezone="Australia/Sydney"
              value={formValues.endDate}
              onChange={(date) =>
                setFormValues((prev) => ({
                  ...prev,
                  endDate: date.length ? formatDateDateTimeWidget(new Date(date)) : '',
                }))
              }
              format="DD/MM/YYYY HH:mm"
              minDate={formValues.startDate ? newDate(formValues.startDate) : undefined}
              label="End Date"
            />
          </Grid>
          <Grid item xs={3} alignSelf="center">
            <Typography color={dealStatus?.color}>{dealStatus?.status}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} marginTop={2}>
          <InputRateCodeSelect
            vendorCode={formValues.cruiseLine?.code}
            selectedRateIds={selectedRateIds}
            selectedRateCodes={selectedRateCodes}
            isLoading={!formValues.cruiseLine}
            onChange={setSelectedRateIds}
          />
        </Grid>

        {!!dealPage && (
          <Grid item xs={12} marginTop={2}>
            <DealInclusionList />
          </Grid>
        )}

        {!dealPage && (
          <Grid item xs={12} marginTop={2}>
            <Typography>You will be able to add inclusions after saving the deal page.</Typography>
          </Grid>
        )}
      </Grid>

      <Box mt={3} gap={1} display="flex" justifyContent="end">
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" type="submit" disabled={!!isFetching}>
          {!!isFetching && <Spinner size={20} />}
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}

export default DealPageForm;
