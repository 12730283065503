import React, { useCallback, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import ImageUploadField from '~/components/Common/Forms/ImageUploadField';
import Image from '~/components/Common/Image';

import cruiseLineService from '~/services/cruises/CruiseLineService';

interface Props {
  cruiseLine: API.CruiseLine;
}

const CruiseLineForm = ({ cruiseLine }: Props) => {
  const [status, setStatus] = useState<string>();
  const brands = ['luxuryescapes', 'cudotravel', 'scoopontravel', 'dealstravel', 'kogantravel'];
  const regions = ['AU', 'NZ'];
  const consumers = ['CRUISECRITIC'];

  const [availableInBrands, setAvailableInBrands] = useState<API.Brand[]>(cruiseLine.availableInBrands || []);
  const [availableInRegions, setAvailableInRegions] = useState<API.Region[]>(cruiseLine.availableInRegions || []);
  const [availableInConsumers, setAvailableInConsumers] = useState<API.Consumer[]>(
    cruiseLine.availableInConsumers || [],
  );
  const [description, setDescription] = useState<string>(cruiseLine.description || '');
  const [lpEnabled, setLpEnabled] = useState<boolean>(cruiseLine.lpEnabled || false);
  const [lpHeroImageId, setLpHeroImageId] = useState<string | undefined>(cruiseLine.lpHeroImageId);
  const [lpMobileHeroImageId, setLpMobileHeroImageId] = useState<string | undefined>(cruiseLine.lpMobileHeroImageId);
  const [whiteImageId, setWhiteImageId] = useState<string | undefined>(cruiseLine.whiteImageId);
  const [smallImageId, setSmallImageId] = useState<string | undefined>(cruiseLine.smallImageId);

  const slug = lpEnabled ? cruiseLine.name?.toLowerCase().replace(/ /g, '-') : null;

  const handlerSelectBrand = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = event.target;
      const isSelected = availableInBrands.includes(name as API.Brand);
      if (isSelected) {
        setAvailableInBrands(availableInBrands.filter((b) => b !== name));
      } else {
        setAvailableInBrands([...availableInBrands, name as API.Brand]);
      }
    },
    [availableInBrands, setAvailableInBrands],
  );

  const handlerSelectRegion = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = event.target;
      const isSelected = availableInRegions.includes(name as API.Region);
      if (isSelected) {
        setAvailableInRegions(availableInRegions.filter((b) => b !== name));
      } else {
        setAvailableInRegions([...availableInRegions, name as API.Region]);
      }
    },
    [availableInRegions, setAvailableInRegions],
  );

  const handlerSelectConsumer = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = event.target;
      const isSelected = availableInConsumers.includes(name as API.Consumer);
      if (isSelected) {
        setAvailableInConsumers(availableInConsumers.filter((b) => b !== name));
      } else {
        setAvailableInConsumers([...availableInConsumers, name as API.Consumer]);
      }
    },
    [availableInConsumers, setAvailableInConsumers],
  );

  const handleUploadLpHeroImage = useCallback(
    (_, cloudinaryId) => {
      setLpHeroImageId(cloudinaryId);
    },
    [setLpHeroImageId],
  );

  const handleUploadLpMobileHeroImage = useCallback(
    (_, cloudinaryId) => {
      setLpMobileHeroImageId(cloudinaryId);
    },
    [setLpMobileHeroImageId],
  );

  const handleUploadWhiteImage = useCallback(
    (_, cloudinaryId) => {
      setWhiteImageId(cloudinaryId);
    },
    [setWhiteImageId],
  );

  const handleUploadSmallImage = useCallback(
    (_, cloudinaryId) => {
      setSmallImageId(cloudinaryId);
    },
    [setSmallImageId],
  );

  const handleSave = useCallback(async () => {
    setStatus('loading');

    try {
      await cruiseLineService.updateCruiseLine(cruiseLine.id, {
        ...cruiseLine,
        description,
        availableInBrands,
        availableInRegions,
        availableInConsumers,
        lpHeroImageId,
        lpMobileHeroImageId,
        lpEnabled,
        slug,
        whiteImageId,
        smallImageId,
      });

      setStatus('success');
    } catch (error) {
      setStatus('error');
    }
  }, [
    cruiseLine,
    description,
    availableInBrands,
    availableInRegions,
    availableInConsumers,
    lpHeroImageId,
    lpMobileHeroImageId,
    lpEnabled,
    slug,
    whiteImageId,
    smallImageId,
  ]);

  return (
    <Stack direction="column" spacing={2}>
      <Stack spacing={2} direction="row">
        <TextField fullWidth label="Code" value={cruiseLine.code} disabled />
        <TextField fullWidth label="Name" value={cruiseLine.name} disabled />
      </Stack>

      <FormGroup>
        <Typography fontWeight="bold">Available In Brands:</Typography>

        <Stack direction="row">
          {brands.map((brand) => (
            <FormControlLabel
              key={brand}
              control={<Checkbox defaultChecked />}
              label={brand}
              name={brand}
              checked={availableInBrands.includes(brand as API.Brand)}
              onChange={handlerSelectBrand}
            />
          ))}
        </Stack>
      </FormGroup>

      <FormGroup>
        <Typography fontWeight="bold">Available In Regions:</Typography>

        <Stack direction="row">
          {regions.map((region) => (
            <FormControlLabel
              key={region}
              control={<Checkbox defaultChecked />}
              label={region}
              name={region}
              checked={availableInRegions.includes(region as API.Region)}
              onChange={handlerSelectRegion}
            />
          ))}
        </Stack>
      </FormGroup>

      <FormGroup>
        <Typography fontWeight="bold">Available In Consumers:</Typography>

        <Stack direction="row">
          {consumers.map((consumer) => (
            <FormControlLabel
              key={consumer}
              control={<Checkbox defaultChecked />}
              label={consumer}
              name={consumer}
              checked={availableInConsumers.includes(consumer as API.Consumer)}
              onChange={handlerSelectConsumer}
            />
          ))}
        </Stack>
      </FormGroup>

      <Divider />

      <FormGroup>
        <Typography fontWeight="bold">Cruise line logos:</Typography>

        <Grid container spacing={1} mt={2}>
          <Grid item xs={4}>
            <Typography mb={2}>Provided by revelex:</Typography>
            <Box>
              <Image publicId={cruiseLine.imageId} alt="Revelex logo" />
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box>
              <ImageUploadField
                col={12}
                label="White logo"
                field_key="whiteImageId"
                value={whiteImageId}
                multiple={false}
                onUpload={handleUploadWhiteImage}
                imageFullWidth
                imageFitType="contain"
                darkBackground
              />
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box>
              <ImageUploadField
                col={12}
                label="Small logo"
                field_key="smallImageId"
                value={smallImageId}
                multiple={false}
                onUpload={handleUploadSmallImage}
                imageFullWidth
                imageFitType="contain"
              />
            </Box>
          </Grid>
        </Grid>
      </FormGroup>

      <Divider />

      <FormGroup>
        <Stack spacing={2}>
          <Typography fontWeight="bold">Landing page settings:</Typography>

          <TextField
            rows={4}
            multiline
            fullWidth
            label="Description"
            value={description}
            onChange={({ target }) => setDescription(target.value)}
          />

          <Stack spacing={2} direction="row" alignItems="center">
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Enable landing page"
              name="Enable landing page"
              checked={lpEnabled}
              onChange={() => setLpEnabled(!lpEnabled)}
            />
            {slug && (
              <Button
                variant="contained"
                href={`${window.configs.LUX_CUSTOMER_PORTAL}/au/cruise-line/${slug}`}
                target="_blank"
                size="small"
              >
                Preview
              </Button>
            )}
          </Stack>

          <Grid container spacing={1} mt={2}>
            <Grid item xs={6}>
              <Box>
                <ImageUploadField
                  col={12}
                  label="Hero image"
                  field_key="cruisePromoBannerImage"
                  value={lpHeroImageId}
                  multiple={false}
                  onUpload={handleUploadLpHeroImage}
                  imageFullWidth
                  imageFitType="contain"
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box>
                <ImageUploadField
                  col={12}
                  label="Mobile hero image"
                  field_key="cruisePromoBannerImage"
                  value={lpMobileHeroImageId}
                  multiple={false}
                  onUpload={handleUploadLpMobileHeroImage}
                  imageFullWidth
                  imageFitType="contain"
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </FormGroup>

      <Box textAlign="right">
        <Button
          variant="contained"
          startIcon={status === 'loading' ? <CircularProgress size={16} /> : undefined}
          onClick={handleSave}
          disabled={status === 'loading'}
        >
          Save cruise line
        </Button>
      </Box>

      {status === 'success' && <Alert severity="success">CRUISE LINE SAVED WITH SUCCESS</Alert>}

      {status === 'error' && <Alert severity="error">ERROR SAVING CRUISE LINE</Alert>}
    </Stack>
  );
};

export default CruiseLineForm;
