import React, { Fragment } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import OrderTag from '~/components/Common/OrderTag';
import PermissionedComponent from '~/components/Common/PermissionedComponent';
import CruisesOrderStatus from '~/components/Purchases/Home/Cruises/CruisesOrderStatus';
import { getBedbankRoomStatus } from '~/components/Purchases/utils/getBedbankRoomStatus';

import { formatDateLong } from '~/services/TimeService';

import experienceVendorFormatter from './experienceVendorFormatter';
import statusFormatter from './statusFormatter';

const hotelItemStatusText = (item: App.OrderItem) =>
  item.status === 'cancelled'
    ? 'Cancelled'
    : item.reservation?.check_in || item.reservation?.start_date
    ? 'Booked'
    : 'Pending dates';

const renderAccommodationItemsInfo = (items: Array<App.OrderItem>) => {
  const locations = [
    ...new Set(
      items
        .map((item) => {
          const location = item.offer?.location ?? '';
          return typeof location === 'string' ? location : location.description;
        })
        .filter(Boolean),
    ),
  ];

  return (
    <>
      {locations.length > 0 && (
        <Box>
          <Typography fontWeight={500}>Location:</Typography>
          {locations.map((location) => (
            <Typography key={location}>{location}</Typography>
          ))}
        </Box>
      )}

      <Box>
        <Typography fontWeight={500}>Accommodation:</Typography>
        {items.map((item) => (
          <Fragment key={item.id}>
            <Typography>
              {statusFormatter(`${item.booking_number} - ${hotelItemStatusText(item)}`, item.status)}
            </Typography>
            <OrderTag items={items} item={item} />
          </Fragment>
        ))}
      </Box>
    </>
  );
};

const renderBedbankItemsInfo = (
  orderCreatedAt: string,
  items: App.Order['bedbank_items'],
  reservationRoomInfo: Record<string, Array<App.Bedbank.ReservationRoomInfo>> | null,
) => {
  return (
    <>
      {items.map((item, index) => {
        const bedbankRoomStatus = getBedbankRoomStatus(item.rooms, reservationRoomInfo?.[item.id_reservation] ?? null);

        return (
          <Box key={`booking_number_${item.id}`}>
            <Box>
              <Typography fontWeight={500}>Location:</Typography>
              <Typography>{item.offer.name}</Typography>
            </Box>
            <Typography fontWeight={500}>
              Booking Number: {statusFormatter(`${item.booking_number}`, item.status)}
            </Typography>
            <Typography fontWeight={500}>
              Supplier Booking Number: <Typography component="span">{item.supplier_booking_number}</Typography>
            </Typography>

            <Typography fontWeight={500}>
              Check In: {statusFormatter(`${formatDateLong(new Date(item.check_in))}`, item.status)}
            </Typography>

            <Typography fontWeight={500}>
              Supplier: <Typography component="span">{item.supplier}</Typography>
            </Typography>

            {bedbankRoomStatus === 'verified' && (
              <Typography component="span" color="green">
                Room Verified
              </Typography>
            )}
            {bedbankRoomStatus === 'unverified' && (
              <Typography component="span" color="warning.main">
                Room Mapping Check
              </Typography>
            )}
            {bedbankRoomStatus === 'altered' && (
              <Typography component="span" color="error.main">
                Incorrect Mapping
              </Typography>
            )}
            <OrderTag items={items} item={item} />

            {index < items.length - 1 && <Divider />}
          </Box>
        );
      })}
    </>
  );
};

const packageInformationFormatter = (
  params: GridRenderCellParams<App.Order>,
  reservationRoomInfo?: Record<string, Array<App.Bedbank.ReservationRoomInfo>> | null,
) => {
  const row = params.row;

  const has_accommodation = row.items?.length > 0;
  const isBundle = row.items?.length > 0 && row.items[0]?.reservation?.bundle;

  return (
    <Stack direction="column" spacing={1}>
      {has_accommodation && renderAccommodationItemsInfo(row.items)}
      {has_accommodation && row.has_bedbank && <Divider />}
      {row.has_bedbank && renderBedbankItemsInfo(row.created_at, row.bedbank_items, reservationRoomInfo)}

      {isBundle && (
        <div>
          <Typography fontWeight={500} color="orange">
            Bundle
          </Typography>
        </div>
      )}

      {(row.has_experience || row.has_addons) && (
        <Box>
          <Typography fontWeight={500} display="inline">
            Experiences:
          </Typography>
          <Typography>
            {row.experience_items.concat(row.addon_items).map((item) => (
              <Fragment key={item.id}>
                {statusFormatter(item.booking_number + ' - ' + experienceVendorFormatter(item.provider), item.status)}
              </Fragment>
            ))}
          </Typography>
        </Box>
      )}

      {row.has_flight && (
        <Box>
          <Typography fontWeight={500} display="inline">
            Flight PNRs:
          </Typography>
          <Typography>
            {row.flight_items.map((item) => (
              <Fragment key={item.id}>{statusFormatter(item.pnr_id, item.status)}</Fragment>
            ))}
          </Typography>
        </Box>
      )}

      {row.has_insurance && (
        <Box>
          <Typography fontWeight={500} display="inline">
            Insurance:
          </Typography>
          <Typography>
            {row.insurance_items.map((item) => (
              <Fragment key={item.id}>{statusFormatter(item.product_id, item.status)}</Fragment>
            ))}
          </Typography>
        </Box>
      )}

      {row.has_gift_card && (
        <Box>
          <Typography fontWeight={500} display="inline">
            Gift Card
          </Typography>
          <PermissionedComponent>
            <Typography>
              {row.gift_card_items.map((item) => (
                <Fragment key={item.id}>{statusFormatter(item.code, '')}</Fragment>
              ))}
            </Typography>
          </PermissionedComponent>
        </Box>
      )}

      {row.has_offline_flight && (
        <Box>
          <Typography fontWeight={500} display="inline">
            Offline Flight:
          </Typography>
          <Typography>
            {row.offline_flight_items?.map((item) => (
              <Fragment key={item.id}>{statusFormatter(item.pnr_ids?.join(', '), item.status)}</Fragment>
            ))}
          </Typography>
        </Box>
      )}

      {row.has_tour && (
        <Box>
          <Typography fontWeight={500} display="inline">
            Tour:
          </Typography>
          <Typography>
            {row.tour_items.map((item) => (
              <Fragment key={item.id}>{statusFormatter(item.booking_number, item.status)}</Fragment>
            ))}
          </Typography>
        </Box>
      )}

      {row.cruise_items && row.cruise_items.length > 0 && (
        <Box>
          <Typography fontWeight={500} display="inline">
            Cruise:
          </Typography>
          {row.cruise_items.map((item) => (
            <Fragment key={item.id}>
              <CruisesOrderStatus status={item.status} bookingNumber={item.booking_number} />
            </Fragment>
          ))}
        </Box>
      )}
    </Stack>
  );
};

export default packageInformationFormatter;
